import { Union } from "./fable_modules/fable-library-js.4.20.0/Types.js";
import { union_type, string_type } from "./fable_modules/fable-library-js.4.20.0/Reflection.js";
import { singleton } from "./fable_modules/fable-library-js.4.20.0/AsyncBuilder.js";
import { Headers_create, Headers_contentType, Http_header, Http_request, Http_method, Http_send } from "./fable_modules/Fable.SimpleHttp.3.6.0/Http.fs.js";
import { HttpMethod } from "./fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { FSharpResult$2, Result_Map } from "./fable_modules/fable-library-js.4.20.0/Result.js";
import { SimpleJson_parseNative, SimpleJson_mapKeys } from "./fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { capitalize } from "../WebTracker.Shared/Extensions.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { Aljex_Shipment_$reflection, GTMS_Shipment_$reflection, TMF_Shipment_$reflection, PagedData$1_$reflection, ShipmentLite_$reflection } from "../WebTracker.Shared/Models.fs.js";
import { some } from "./fable_modules/fable-library-js.4.20.0/Option.js";

export class TrackingNumber extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["TrackingNumber"];
    }
}

export function TrackingNumber_$reflection() {
    return union_type("WebTracker.UI.Api.TrackingNumber", [], TrackingNumber, () => [[["Item", string_type]]]);
}

export class ShipmentId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ShipmentId"];
    }
}

export function ShipmentId_$reflection() {
    return union_type("WebTracker.UI.Api.ShipmentId", [], ShipmentId, () => [[["Item", string_type]]]);
}

export class CustomerType extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["CustomerType"];
    }
}

export function CustomerType_$reflection() {
    return union_type("WebTracker.UI.Api.CustomerType", [], CustomerType, () => [[["Item", string_type]]]);
}

export function searchShipments(_arg, page, returnTotalCount) {
    const trackingNumber = _arg.fields[0];
    return singleton.Delay(() => {
        let req_2, req_1;
        const baseAddress = process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : '';
        const apiKey = process.env.API_KEY ? process.env.API_KEY : '';
        const trackingNumberEncoded = encodeURIComponent(trackingNumber);
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${baseAddress}/api/shipment/search?trackingNumber=${trackingNumberEncoded}&page=${page}&returnTotalCount=${returnTotalCount}`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_create("X-CRANE-API-KEY", apiKey), req_2))), (_arg_1) => {
            let input_1;
            const response = _arg_1;
            let result_1;
            if (response.statusCode === 200) {
                result_1 = Result_Map((x) => x, (input_1 = SimpleJson_mapKeys(capitalize, SimpleJson_parseNative(response.responseText)), (() => {
                    try {
                        return new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedData$1_$reflection(ShipmentLite_$reflection())))]);
                    }
                    catch (ex) {
                        return new FSharpResult$2(1, [ex.message]);
                    }
                })()));
            }
            else {
                console.error(some(`API error: ${response.responseText}`));
                result_1 = (new FSharpResult$2(1, [`Failed to find shipments/loads by tracking # ${trackingNumber}`]));
            }
            return singleton.Return(result_1);
        });
    });
}

export function findCWWShipment(_arg) {
    const shipmentId = _arg.fields[0];
    return singleton.Delay(() => {
        let req_2, req_1;
        const baseAddress = process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : '';
        const apiKey = process.env.API_KEY ? process.env.API_KEY : '';
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${baseAddress}/api/shipment/find?shipmentId=${shipmentId}&customerType=CWW`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_create("X-CRANE-API-KEY", apiKey), req_2))), (_arg_1) => {
            const response = _arg_1;
            let result;
            if (response.statusCode === 200) {
                const input_1 = SimpleJson_mapKeys(capitalize, SimpleJson_parseNative(response.responseText));
                try {
                    result = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(TMF_Shipment_$reflection()))]));
                }
                catch (ex) {
                    result = (new FSharpResult$2(1, [ex.message]));
                }
            }
            else {
                console.error(some(`API error: ${response.responseText}`));
                result = (new FSharpResult$2(1, [`Failed to find shipment with ID ${shipmentId}`]));
            }
            return singleton.Return(result);
        });
    });
}

export function findCMTSShipment(_arg) {
    const shipmentId = _arg.fields[0];
    return singleton.Delay(() => {
        let req_2, req_1;
        const baseAddress = process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : '';
        const apiKey = process.env.API_KEY ? process.env.API_KEY : '';
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${baseAddress}/api/shipment/find?shipmentId=${shipmentId}&customerType=CMTS`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_create("X-CRANE-API-KEY", apiKey), req_2))), (_arg_1) => {
            const response = _arg_1;
            let result;
            if (response.statusCode === 200) {
                const input_1 = SimpleJson_mapKeys(capitalize, SimpleJson_parseNative(response.responseText));
                try {
                    result = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(GTMS_Shipment_$reflection()))]));
                }
                catch (ex) {
                    result = (new FSharpResult$2(1, [ex.message]));
                }
            }
            else {
                console.error(some(`API error: ${response.responseText}`));
                result = (new FSharpResult$2(1, [`Failed to find shipment with ID ${shipmentId}`]));
            }
            return singleton.Return(result);
        });
    });
}

export function findCFSCSLShipment(_arg) {
    const shipmentId = _arg.fields[0];
    return singleton.Delay(() => {
        let req_2, req_1;
        const baseAddress = process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : '';
        const apiKey = process.env.API_KEY ? process.env.API_KEY : '';
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${baseAddress}/api/shipment/find?shipmentId=${shipmentId}&customerType=CFSCSL`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_create("X-CRANE-API-KEY", apiKey), req_2))), (_arg_1) => {
            const response = _arg_1;
            let result;
            if (response.statusCode === 200) {
                const input_1 = SimpleJson_mapKeys(capitalize, SimpleJson_parseNative(response.responseText));
                try {
                    result = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(Aljex_Shipment_$reflection()))]));
                }
                catch (ex) {
                    result = (new FSharpResult$2(1, [ex.message]));
                }
            }
            else {
                console.error(some(`API error: ${response.responseText}`));
                result = (new FSharpResult$2(1, [`Failed to find shipment with ID ${shipmentId}`]));
            }
            return singleton.Return(result);
        });
    });
}

