import { Union } from "./fable_modules/fable-library-js.4.20.0/Types.js";
import { union_type } from "./fable_modules/fable-library-js.4.20.0/Reflection.js";

export class AsyncMsg$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Started", "Completed"];
    }
}

export function AsyncMsg$1_$reflection(gen0) {
    return union_type("WebTracker.UI.Extensions.AsyncMsg`1", [gen0], AsyncMsg$1, () => [[], [["Item", gen0]]]);
}

export class Deferred$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["HasNotStartedYet", "InProgress", "Resolved"];
    }
}

export function Deferred$1_$reflection(gen0) {
    return union_type("WebTracker.UI.Extensions.Deferred`1", [gen0], Deferred$1, () => [[], [], [["Item", gen0]]]);
}

export function Deferred_resolved(deferred) {
    switch (deferred.tag) {
        case 1:
            return false;
        case 2:
            return true;
        default:
            return false;
    }
}

export function Deferred_map(transform, deferred) {
    switch (deferred.tag) {
        case 1:
            return new Deferred$1(1, []);
        case 2:
            return new Deferred$1(2, [transform(deferred.fields[0])]);
        default:
            return new Deferred$1(0, []);
    }
}

export function Deferred_bind(transform, deferred) {
    switch (deferred.tag) {
        case 1:
            return new Deferred$1(1, []);
        case 2:
            return transform(deferred.fields[0]);
        default:
            return new Deferred$1(0, []);
    }
}

export function Deferred_exists(predicate, _arg) {
    switch (_arg.tag) {
        case 1:
            return false;
        case 2:
            return predicate(_arg.fields[0]);
        default:
            return false;
    }
}

export function Deferred_iter(action, deferred) {
    switch (deferred.tag) {
        case 1: {
            break;
        }
        case 2: {
            action(deferred.fields[0]);
            break;
        }
        default:
            undefined;
    }
}

