import { createElement } from "react";
import { join } from "./fable_modules/fable-library-js.4.20.0/String.js";
import { createObj } from "./fable_modules/fable-library-js.4.20.0/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { ofArray } from "./fable_modules/fable-library-js.4.20.0/List.js";

export function error(message) {
    return createElement("h1", {
        className: join(" ", ["has-text-centered", "m-5", "has-text-danger"]),
        children: message,
    });
}

export function info(message) {
    return createElement("h1", {
        className: join(" ", ["has-text-centered", "m-5"]),
        children: message,
    });
}

export function helpDialog(closeDialog) {
    let elems_6, elems_5, elems, elems_3, elems_1, elems_2, elems_4;
    return createElement("div", createObj(ofArray([["className", join(" ", ["modal", "is-active", "px-4"])], (elems_6 = [createElement("div", {
        className: "modal-background",
    }), createElement("div", createObj(ofArray([["className", "modal-card"], ["style", {
        maxWidth: 450,
    }], (elems_5 = [createElement("header", createObj(ofArray([["className", "modal-card-head"], (elems = [createElement("p", {
        className: join(" ", ["modal-card-title", "has-text-centered", "is-size-6"]),
        children: "Search Shipments/Loads By Tracking #",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("section", createObj(ofArray([["className", "modal-card-body"], (elems_3 = [createElement("p", {
        className: join(" ", ["my-4"]),
        children: "The tracking # will be matched against the following fields:",
    }), createElement("ul", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement("li", {
        className: join(" ", ["my-1"]),
        children: "AWB/BOL #",
    }), createElement("li", {
        className: join(" ", ["my-1"]),
        children: "MAWB/MBL #",
    }), createElement("li", {
        className: join(" ", ["my-1"]),
        children: "Booking #",
    }), createElement("li", {
        className: join(" ", ["my-1"]),
        children: "Job #",
    }), createElement("li", {
        className: join(" ", ["my-1"]),
        children: "Reference #",
    }), createElement("li", {
        className: join(" ", ["my-1"]),
        children: "Invoice #",
    }), createElement("li", {
        className: join(" ", ["my-1"]),
        children: "Container #",
    }), createElement("li", {
        className: join(" ", ["my-1"]),
        children: "Pro #",
    }), createElement("li", {
        className: join(" ", ["my-1"]),
        children: "Load #",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["block", "my-4", "has-text-centered"])], (elems_2 = [createElement("a", {
        className: join(" ", ["has-text-link"]),
        href: "mailto:cview.support@craneww.com",
        children: "In case of issues, please contact cview.support@craneww.com",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("footer", createObj(ofArray([["className", "modal-card-foot"], (elems_4 = [createElement("button", {
        className: join(" ", ["button", "is-fullwidth", "is-small", "is-primary"]),
        onClick: closeDialog,
        type: "button",
        children: "OK",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

