import { toString as toString_1, Union, Record } from "./fable_modules/fable-library-js.4.20.0/Types.js";
import { record_type, union_type, list_type, int32_type, option_type, string_type, bool_type } from "./fable_modules/fable-library-js.4.20.0/Reflection.js";
import { PagedData$1_$reflection, Aljex_Shipment_$reflection, GTMS_Shipment_$reflection, TMF_Shipment_$reflection, ShipmentLite_$reflection } from "../WebTracker.Shared/Models.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.20.0/Result.js";
import { Deferred_resolved, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "./Extensions.fs.js";
import { printf, toText, join, substring, isNullOrWhiteSpace } from "./fable_modules/fable-library-js.4.20.0/String.js";
import { length, ofArray, concat, singleton, empty, tail, head, isEmpty } from "./fable_modules/fable-library-js.4.20.0/List.js";
import { Route_$007CQuery$007C_$007C } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { toArray, value as value_676, ofNullable, defaultArg } from "./fable_modules/fable-library-js.4.20.0/Option.js";
import { maxValue, compare, minValue, timeOfDay, toString } from "./fable_modules/fable-library-js.4.20.0/Date.js";
import { RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { sortBy, sortByDescending, tryFind, item } from "./fable_modules/fable-library-js.4.20.0/Array.js";
import { defaultOf as defaultOf_1, createObj, equals } from "./fable_modules/fable-library-js.4.20.0/Util.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "./fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { findCFSCSLShipment, findCMTSShipment, ShipmentId as ShipmentId_2, findCWWShipment, TrackingNumber as TrackingNumber_2, searchShipments as searchShipments_1 } from "./Api.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { iterate, collect, map, empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library-js.4.20.0/Seq.js";
import { defaultOf } from "./fable_modules/Feliz.2.7.0/../fable-library-js.4.20.0/Util.js";
import { info, helpDialog, error as error_2 } from "./Components.fs.js";

export class State extends Record {
    constructor(ShowMenuItems, ShowHelpDialog, TrackingNumber, ShipmentId, CustomerType, ShipmentsPage, TotalShipments, Shipments, ShipmentResults, CWWShipment, CMTSShipment, CFSCSLShipment) {
        super();
        this.ShowMenuItems = ShowMenuItems;
        this.ShowHelpDialog = ShowHelpDialog;
        this.TrackingNumber = TrackingNumber;
        this.ShipmentId = ShipmentId;
        this.CustomerType = CustomerType;
        this.ShipmentsPage = (ShipmentsPage | 0);
        this.TotalShipments = (TotalShipments | 0);
        this.Shipments = Shipments;
        this.ShipmentResults = ShipmentResults;
        this.CWWShipment = CWWShipment;
        this.CMTSShipment = CMTSShipment;
        this.CFSCSLShipment = CFSCSLShipment;
    }
}

export function State_$reflection() {
    return record_type("WebTracker.UI.Application.State", [], State, () => [["ShowMenuItems", bool_type], ["ShowHelpDialog", bool_type], ["TrackingNumber", option_type(string_type)], ["ShipmentId", option_type(string_type)], ["CustomerType", option_type(string_type)], ["ShipmentsPage", int32_type], ["TotalShipments", int32_type], ["Shipments", list_type(ShipmentLite_$reflection())], ["ShipmentResults", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(ShipmentLite_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(ShipmentLite_$reflection())]], [["ErrorValue", string_type]]]))], ["CWWShipment", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [TMF_Shipment_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", TMF_Shipment_$reflection()]], [["ErrorValue", string_type]]]))], ["CMTSShipment", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GTMS_Shipment_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GTMS_Shipment_$reflection()]], [["ErrorValue", string_type]]]))], ["CFSCSLShipment", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Aljex_Shipment_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Aljex_Shipment_$reflection()]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetShowMenuItems", "ShowHelpDialog", "SetTrackingNumber", "SetShipmentId", "SearchShipments", "GetMoreShipments", "GetShipments", "GetCWWShipment", "GetCMTSShipment", "GetCFSCSLShipment"];
    }
}

export function Msg_$reflection() {
    return union_type("WebTracker.UI.Application.Msg", [], Msg, () => [[], [["Item", bool_type]], [["trackingNumber", string_type], ["isDeepLink", bool_type]], [["shipmentId", option_type(string_type)], ["customerType", option_type(string_type)]], [], [], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedData$1_$reflection(ShipmentLite_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagedData$1_$reflection(ShipmentLite_$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [TMF_Shipment_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", TMF_Shipment_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GTMS_Shipment_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GTMS_Shipment_$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Aljex_Shipment_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Aljex_Shipment_$reflection()]], [["ErrorValue", string_type]]]))]]]);
}

function tryParseTrackingNumber(segments) {
    let matchResult, trackingNumber;
    if (!isEmpty(segments)) {
        const activePatternResult = Route_$007CQuery$007C_$007C(head(segments));
        if (activePatternResult != null) {
            if (!isEmpty(activePatternResult)) {
                if (head(activePatternResult)[0] === "trackingNumber") {
                    if (isEmpty(tail(activePatternResult))) {
                        if (isEmpty(tail(segments))) {
                            matchResult = 0;
                            trackingNumber = head(activePatternResult)[1];
                        }
                        else {
                            matchResult = 1;
                        }
                    }
                    else {
                        matchResult = 1;
                    }
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            if (isNullOrWhiteSpace(trackingNumber)) {
                return undefined;
            }
            else {
                return trackingNumber.trim();
            }
        default:
            return undefined;
    }
}

function hasTrackingNumber(state) {
    const matchValue = state.TrackingNumber;
    if (matchValue != null) {
        return !isNullOrWhiteSpace(matchValue);
    }
    else {
        return false;
    }
}

function formatDateTime(value) {
    let input_1;
    return defaultArg((input_1 = ofNullable(value), (input_1 == null) ? undefined : toString(input_1, "MM/dd/yyyy")), "");
}

function formatDateAndTime(value) {
    let input_1, dateTime;
    return defaultArg((input_1 = ofNullable(value), (input_1 == null) ? undefined : ((dateTime = input_1, (timeOfDay(dateTime) === 0) ? formatDateTime(dateTime) : toString(dateTime, "MM/dd/yyyy HH:mm", {})))), "");
}

function formatTime(value) {
    if (isNullOrWhiteSpace(value)) {
        return "";
    }
    else if (value.length === 4) {
        return `${substring(value, 0, 2)}:${substring(value, 2, 2)}`;
    }
    else {
        return value;
    }
}

function formatNullable(value) {
    let input_1;
    return defaultArg((input_1 = ofNullable(value), (input_1 == null) ? undefined : toString_1(value_676(input_1))), "");
}

function formatLocation(x, y) {
    if (isNullOrWhiteSpace(x) ? true : isNullOrWhiteSpace(y)) {
        return "";
    }
    else {
        return `${x}, ${y}`;
    }
}

export function init() {
    const trackingNumberOption = tryParseTrackingNumber(RouterModule_urlSegments(window.location.hash, 1));
    return [new State(false, false, trackingNumberOption, undefined, undefined, 1, 0, empty(), new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, [])), (trackingNumberOption != null) ? singleton((dispatch) => {
        dispatch(new Msg(6, [new AsyncMsg$1(0, [])]));
    }) : Cmd_none()];
}

export function update(message, state) {
    let msg_2;
    switch (message.tag) {
        case 1:
            return [new State(state.ShowMenuItems, message.fields[0], state.TrackingNumber, state.ShipmentId, state.CustomerType, state.ShipmentsPage, state.TotalShipments, state.Shipments, state.ShipmentResults, state.CWWShipment, state.CMTSShipment, state.CFSCSLShipment), Cmd_none()];
        case 2:
            if (isNullOrWhiteSpace(message.fields[0])) {
                return [new State(state.ShowMenuItems, state.ShowHelpDialog, undefined, state.ShipmentId, state.CustomerType, state.ShipmentsPage, state.TotalShipments, state.Shipments, state.ShipmentResults, state.CWWShipment, state.CMTSShipment, state.CFSCSLShipment), Cmd_none()];
            }
            else {
                return [new State(state.ShowMenuItems, state.ShowHelpDialog, message.fields[0], state.ShipmentId, state.CustomerType, state.ShipmentsPage, state.TotalShipments, state.Shipments, state.ShipmentResults, state.CWWShipment, state.CMTSShipment, state.CFSCSLShipment), message.fields[1] ? singleton((dispatch) => {
                    dispatch(new Msg(6, [new AsyncMsg$1(0, [])]));
                }) : Cmd_none()];
            }
        case 3:
            return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, message.fields[0], message.fields[1], 1, 0, empty(), state.ShipmentResults, state.CWWShipment, state.CMTSShipment, state.CFSCSLShipment), (msg_2 = ((message.fields[1] != null) ? ((message.fields[1] === "CMTS") ? (new Msg(8, [new AsyncMsg$1(0, [])])) : ((message.fields[1] === "CFSCSL") ? (new Msg(9, [new AsyncMsg$1(0, [])])) : (new Msg(7, [new AsyncMsg$1(0, [])])))) : (new Msg(7, [new AsyncMsg$1(0, [])]))), singleton((dispatch_1) => {
                dispatch_1(msg_2);
            }))];
        case 4:
            return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, undefined, undefined, 1, 0, empty(), new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, [])), singleton((dispatch_2) => {
                dispatch_2(new Msg(6, [new AsyncMsg$1(0, [])]));
            })];
        case 5:
            return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, state.ShipmentId, state.CustomerType, state.ShipmentsPage + 1, state.TotalShipments, state.Shipments, state.ShipmentResults, state.CWWShipment, state.CMTSShipment, state.CFSCSLShipment), singleton((dispatch_3) => {
                dispatch_3(new Msg(6, [new AsyncMsg$1(0, [])]));
            })];
        case 6:
            if (message.fields[0].tag === 1) {
                let patternInput;
                if (message.fields[0].fields[0].tag === 0) {
                    const shipments = message.fields[0].fields[0].fields[0];
                    if (shipments.TotalCount === 1) {
                        const shipment = item(0, shipments.Data);
                        const matchValue_1 = shipment.CustomerType;
                        patternInput = ((matchValue_1 === "CMTS") ? [shipment.ShipmentId, "CMTS", singleton((dispatch_4) => {
                            dispatch_4(new Msg(8, [new AsyncMsg$1(0, [])]));
                        }), shipments.Page, shipments.TotalCount, shipments.Data] : ((matchValue_1 === "CFSCSL") ? [shipment.ShipmentId, "CFSCSL", singleton((dispatch_5) => {
                            dispatch_5(new Msg(9, [new AsyncMsg$1(0, [])]));
                        }), shipments.Page, shipments.TotalCount, shipments.Data] : [shipment.ShipmentId, "CWW", singleton((dispatch_6) => {
                            dispatch_6(new Msg(7, [new AsyncMsg$1(0, [])]));
                        }), shipments.Page, shipments.TotalCount, shipments.Data]));
                    }
                    else {
                        patternInput = [undefined, undefined, Cmd_none(), shipments.Page, shipments.TotalCount, shipments.Data];
                    }
                }
                else {
                    patternInput = [undefined, undefined, Cmd_none(), 1, 0, new Array(0)];
                }
                const shipmentList = concat([state.Shipments, ofArray(patternInput[5])]);
                return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, patternInput[0], patternInput[1], patternInput[3], (state.TotalShipments === 0) ? patternInput[4] : state.TotalShipments, shipmentList, new Deferred$1(2, [(message.fields[0].fields[0].tag === 1) ? (new FSharpResult$2(1, [message.fields[0].fields[0].fields[0]])) : (new FSharpResult$2(0, [shipmentList]))]), state.CWWShipment, state.CMTSShipment, state.CFSCSLShipment), patternInput[2]];
            }
            else if (equals(state.ShipmentResults, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                const matchValue = state.TrackingNumber;
                if (matchValue != null) {
                    const trackingNumber_1 = matchValue;
                    return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, undefined, undefined, state.ShipmentsPage, state.TotalShipments, state.Shipments, new Deferred$1(1, []), new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, [])), Cmd_OfAsyncWith_perform((x) => {
                        Cmd_OfAsync_start(x);
                    }, () => searchShipments_1(new TrackingNumber_2(trackingNumber_1), state.ShipmentsPage, state.TotalShipments === 0), undefined, (arg) => (new Msg(6, [new AsyncMsg$1(1, [arg])])))];
                }
                else {
                    return [state, Cmd_none()];
                }
            }
        case 7:
            if (message.fields[0].tag === 1) {
                return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, state.ShipmentId, state.CustomerType, state.ShipmentsPage, state.TotalShipments, state.Shipments, state.ShipmentResults, new Deferred$1(2, [message.fields[0].fields[0]]), new Deferred$1(0, []), new Deferred$1(0, [])), Cmd_none()];
            }
            else if (equals(state.CWWShipment, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                const matchValue_3 = state.ShipmentId;
                if (matchValue_3 != null) {
                    const shipmentId_2 = matchValue_3;
                    return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, state.ShipmentId, state.CustomerType, state.ShipmentsPage, state.TotalShipments, state.Shipments, state.ShipmentResults, new Deferred$1(1, []), state.CMTSShipment, state.CFSCSLShipment), Cmd_OfAsyncWith_perform((x_1) => {
                        Cmd_OfAsync_start(x_1);
                    }, () => findCWWShipment(new ShipmentId_2(shipmentId_2)), undefined, (arg_2) => (new Msg(7, [new AsyncMsg$1(1, [arg_2])])))];
                }
                else {
                    return [state, Cmd_none()];
                }
            }
        case 8:
            if (message.fields[0].tag === 1) {
                return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, state.ShipmentId, state.CustomerType, state.ShipmentsPage, state.TotalShipments, state.Shipments, state.ShipmentResults, new Deferred$1(0, []), new Deferred$1(2, [message.fields[0].fields[0]]), new Deferred$1(0, [])), Cmd_none()];
            }
            else if (equals(state.CMTSShipment, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                const matchValue_4 = state.ShipmentId;
                if (matchValue_4 != null) {
                    const shipmentId_3 = matchValue_4;
                    return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, state.ShipmentId, state.CustomerType, state.ShipmentsPage, state.TotalShipments, state.Shipments, state.ShipmentResults, state.CWWShipment, new Deferred$1(1, []), state.CFSCSLShipment), Cmd_OfAsyncWith_perform((x_2) => {
                        Cmd_OfAsync_start(x_2);
                    }, () => findCMTSShipment(new ShipmentId_2(shipmentId_3)), undefined, (arg_4) => (new Msg(8, [new AsyncMsg$1(1, [arg_4])])))];
                }
                else {
                    return [state, Cmd_none()];
                }
            }
        case 9:
            if (message.fields[0].tag === 1) {
                return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, state.ShipmentId, state.CustomerType, state.ShipmentsPage, state.TotalShipments, state.Shipments, state.ShipmentResults, new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(2, [message.fields[0].fields[0]])), Cmd_none()];
            }
            else if (equals(state.CFSCSLShipment, new Deferred$1(1, []))) {
                return [state, Cmd_none()];
            }
            else {
                const matchValue_5 = state.ShipmentId;
                if (matchValue_5 != null) {
                    const shipmentId_4 = matchValue_5;
                    return [new State(state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, state.ShipmentId, state.CustomerType, state.ShipmentsPage, state.TotalShipments, state.Shipments, state.ShipmentResults, state.CWWShipment, new Deferred$1(1, []), state.CFSCSLShipment), Cmd_OfAsyncWith_perform((x_3) => {
                        Cmd_OfAsync_start(x_3);
                    }, () => findCFSCSLShipment(new ShipmentId_2(shipmentId_4)), undefined, (arg_6) => (new Msg(9, [new AsyncMsg$1(1, [arg_6])])))];
                }
                else {
                    return [state, Cmd_none()];
                }
            }
        default:
            return [new State(!state.ShowMenuItems, state.ShowHelpDialog, state.TrackingNumber, state.ShipmentId, state.CustomerType, state.ShipmentsPage, state.TotalShipments, state.Shipments, state.ShipmentResults, state.CWWShipment, state.CMTSShipment, state.CFSCSLShipment), Cmd_none()];
    }
}

export function render(state, dispatch) {
    let elems_5, elems_2, elems, elems_1, elems_4, elems_3, elems_115, elems_117, elems_116, elems_122, elems_121, elems_120, elems_118, elems_119, elements;
    const navbar = createElement("nav", createObj(ofArray([["className", join(" ", ["navbar", "is-fixed-top", "is-white", "has-shadow"])], ["role", "navigation"], ["aria-label", "main navigation"], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["navbar-brand"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["navbar-item"])], (elems = [createElement("img", {
        alt: "Crane Logo",
        src: "https://craneww-assets.azureedge.net/assets/crane-logo.svg",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("a", createObj(ofArray([["aria-expanded", false], ["aria-label", "menu"], ["className", join(" ", toList(delay(() => append(singleton_1("navbar-burger"), delay(() => (state.ShowMenuItems ? singleton_1("is-active") : empty_1()))))))], ["role", "button"], ["onClick", (_arg) => {
        dispatch(new Msg(0, []));
    }], (elems_1 = [createElement("span", {
        "aria-hidden": true,
    }), createElement("span", {
        "aria-hidden": true,
    }), createElement("span", {
        "aria-hidden": true,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("navbar-menu"), delay(() => (state.ShowMenuItems ? singleton_1("is-active") : empty_1()))))))], (elems_4 = [createElement("div", {
        className: "navbar-start",
    }), createElement("div", createObj(ofArray([["className", "navbar-end"], (elems_3 = [createElement("a", {
        className: join(" ", ["navbar-item", "is-size-7", "has-text-link", "has-text-weight-semibold"]),
        children: "Services",
        href: "https://craneww.com/solutions/",
        target: "_blank",
    }), createElement("a", {
        className: join(" ", ["navbar-item", "is-size-7", "has-text-link", "has-text-weight-semibold"]),
        children: "Industries",
        href: "https://craneww.com/industries/",
        target: "_blank",
    }), createElement("a", {
        className: join(" ", ["navbar-item", "is-size-7", "has-text-link", "has-text-weight-semibold"]),
        children: "Technology",
        href: "https://craneww.com/logistics-technology/",
        target: "_blank",
    }), createElement("a", {
        className: join(" ", ["navbar-item", "is-size-7", "has-text-link", "has-text-weight-semibold"]),
        children: "About Us",
        href: "https://craneww.com/about-us/",
        target: "_blank",
    }), createElement("a", {
        className: join(" ", ["navbar-item", "is-size-7", "has-text-link", "has-text-weight-semibold"]),
        children: "Knowledge Center",
        href: "https://craneww.com/knowledge-center/",
        target: "_blank",
    }), createElement("a", {
        className: join(" ", ["navbar-item", "is-size-7", "has-text-link", "has-text-weight-semibold"]),
        children: "Locations",
        href: "https://craneww.com/locations/",
        target: "_blank",
    }), createElement("a", {
        className: join(" ", ["navbar-item", "is-size-7", "has-text-link", "has-text-weight-semibold"]),
        children: "Careers",
        href: "https://craneww.com/careers/",
        target: "_blank",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
    const renderShipmentSelection = (shipments) => {
        let elems_18, elems_17, elems_16;
        return createElement("div", createObj(ofArray([["style", {
            maxWidth: 450,
        }], ["className", join(" ", ["mx-auto", "my-4", "is-flex", "is-flex-direction-column", "is-justify-content-center"])], (elems_18 = [createElement("div", {
            className: join(" ", ["box", "has-text-weight-semibold", "has-text-primary", "has-text-centered", "is-uppercase"]),
            children: `${state.TotalShipments} shipments/loads matched the tracking #`,
        }), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_17 = [createElement("ul", createObj(ofArray([["className", join(" ", [])], (elems_16 = toList(delay(() => map((shipment) => {
            let elems_15;
            const children = singleton(createElement("div", createObj(ofArray([["className", join(" ", ["box", "my-3", "is-clickable"])], ["onClick", (_arg_4) => {
                dispatch(new Msg(3, [shipment.ShipmentId, shipment.CustomerType]));
            }], (elems_15 = toList(delay(() => {
                let elems_10;
                return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between"])], (elems_10 = [createElement("span", {
                    className: join(" ", ["has-text-weight-semibold", "is-uppercase"]),
                    children: shipment.HumanReadableIdLabel,
                }), createElement("span", {
                    className: join(" ", []),
                    children: shipment.HumanReadableId,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))), delay(() => {
                    let elems_11, tupledArg;
                    return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between"])], (elems_11 = [createElement("span", {
                        className: join(" ", ["has-text-weight-semibold", "is-uppercase"]),
                        children: "Origin",
                    }), createElement("span", {
                        className: join(" ", []),
                        children: (tupledArg = [shipment.Origin, shipment.OriginCountryCode], formatLocation(tupledArg[0], tupledArg[1])),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))), delay(() => {
                        let elems_12, tupledArg_1;
                        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between"])], (elems_12 = [createElement("span", {
                            className: join(" ", ["has-text-weight-semibold", "is-uppercase"]),
                            children: "Destination",
                        }), createElement("span", {
                            className: join(" ", []),
                            children: (tupledArg_1 = [shipment.Destination, shipment.DestinationCountryCode], formatLocation(tupledArg_1[0], tupledArg_1[1])),
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))), delay(() => {
                            let elems_13;
                            return (shipment.CustomerType === "CWW") ? append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between"])], (elems_13 = [createElement("span", {
                                className: join(" ", ["has-text-weight-semibold", "is-uppercase"]),
                                children: "Service Type",
                            }), createElement("span", {
                                className: join(" ", []),
                                children: shipment.ServiceType,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))), delay(() => {
                                let elems_14;
                                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between"])], (elems_14 = [createElement("span", {
                                    className: join(" ", ["has-text-weight-semibold", "is-uppercase"]),
                                    children: "Service Level",
                                }), createElement("span", {
                                    className: join(" ", []),
                                    children: shipment.ServiceLevel,
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))));
                            })) : empty_1();
                        }));
                    }));
                }));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))));
            return createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            });
        }, shipments))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])));
    };
    const renderShipmentDetails = (deferred, renderShipment) => {
        switch (deferred.tag) {
            case 1:
                return defaultOf();
            case 2: {
                const result = deferred.fields[0];
                if (result.tag === 1) {
                    return error_2(result.fields[0]);
                }
                else {
                    return renderShipment(result.fields[0]);
                }
            }
            default:
                return defaultOf();
        }
    };
    const main = createElement("main", createObj(ofArray([["style", {
        marginBottom: 100,
    }], ["className", join(" ", ["p-6", "is-size-7"])], (elems_115 = toList(delay(() => {
        let inProgress, elems_7, elems_6, value_88;
        return append(singleton_1((inProgress = (((equals(state.ShipmentResults, new Deferred$1(1, [])) ? true : equals(state.CWWShipment, new Deferred$1(1, []))) ? true : equals(state.CFSCSLShipment, new Deferred$1(1, []))) ? true : equals(state.CMTSShipment, new Deferred$1(1, []))), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-center"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center"])], (elems_6 = [createElement("span", {
            className: join(" ", ["material-icons", "py-2", "mx-2", "has-text-primary", "is-clickable"]),
            children: "help",
            onClick: (_arg_1) => {
                dispatch(new Msg(1, [true]));
            },
        }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["type", "text"], ["disabled", inProgress], ["onKeyPress", (keyPress) => {
            if (keyPress.key === "Enter") {
                dispatch(new Msg(4, []));
            }
        }], (value_88 = defaultArg(state.TrackingNumber, defaultOf_1()), ["ref", (e) => {
            if (!(e == null) && !equals(e.value, value_88)) {
                e.value = value_88;
            }
        }]), ["onChange", (ev) => {
            dispatch(new Msg(2, [ev.target.value, false]));
        }], ["placeholder", "Enter tracking #"]]))), createElement("button", {
            className: join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-primary"), delay(() => append(singleton_1("ml-2"), delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("is-uppercase"), delay(() => (inProgress ? singleton_1("is-loading") : empty_1())))))))))))))))),
            disabled: !hasTrackingNumber(state) ? true : inProgress,
            onClick: (_arg_2) => {
                dispatch(new Msg(4, []));
            },
            type: "button",
            children: "Track",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))))), delay(() => append(state.ShowHelpDialog ? singleton_1(helpDialog((_arg_5) => {
            dispatch(new Msg(1, [false]));
        })) : empty_1(), delay(() => {
            if (state.ShipmentId == null) {
                const matchValue_14 = state.ShipmentResults;
                switch (matchValue_14.tag) {
                    case 1:
                        return (length(state.Shipments) === 0) ? singleton_1(info("Please wait while we track your shipment/load...")) : singleton_1(renderShipmentSelection(state.Shipments));
                    case 2: {
                        const matchValue_15 = matchValue_14.fields[0];
                        if (matchValue_15.tag === 0) {
                            const shipments_1 = matchValue_15.fields[0];
                            return isEmpty(shipments_1) ? singleton_1(info("No shipments/loads matched the tracking #")) : append(singleton_1(renderShipmentSelection(shipments_1)), delay(() => {
                                let inProgress_1, elems_9, elems_8;
                                return singleton_1((inProgress_1 = equals(state.ShipmentResults, new Deferred$1(1, [])), (length(state.Shipments) < state.TotalShipments) ? createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-center"])], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap"])], (elems_8 = [createElement("button", {
                                    className: join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-primary"), delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("is-uppercase"), delay(() => (inProgress_1 ? singleton_1("is-loading") : empty_1())))))))))))))),
                                    disabled: inProgress_1,
                                    onClick: (_arg_3) => {
                                        dispatch(new Msg(5, []));
                                    },
                                    type: "button",
                                    children: "Load More",
                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))) : defaultOf()));
                            }));
                        }
                        else {
                            return singleton_1(error_2(matchValue_15.fields[0]));
                        }
                    }
                    default:
                        return (length(state.Shipments) === 0) ? singleton_1(defaultOf()) : singleton_1(renderShipmentSelection(state.Shipments));
                }
            }
            else {
                return Deferred_resolved(state.CWWShipment) ? singleton_1(renderShipmentDetails(state.CWWShipment, (shipment_1) => {
                    let tupledArg_2, tupledArg_3, tupledArg_4, tupledArg_5, elems_48;
                    const shipmentDetailsFields = ofArray([["AWB/BOL #", shipment_1.AwbNumber], ["MAWB/MBL #", shipment_1.MasterAwbNumber], ["Job #", shipment_1.JobNumber], ["Booking #", shipment_1.BookingNumber], ["Origin", (tupledArg_2 = [shipment_1.Origin, shipment_1.OriginCountry], formatLocation(tupledArg_2[0], tupledArg_2[1]))], ["Port Of Export", (tupledArg_3 = [shipment_1.PolName, shipment_1.OriginCountry], formatLocation(tupledArg_3[0], tupledArg_3[1]))], ["Port Of Destination", (tupledArg_4 = [shipment_1.PodName, shipment_1.DestinationCountry], formatLocation(tupledArg_4[0], tupledArg_4[1]))], ["Destination", (tupledArg_5 = [shipment_1.Destination, shipment_1.DestinationCountry], formatLocation(tupledArg_5[0], tupledArg_5[1]))]]);
                    const goodsDetailsFields = ofArray([["Weight", formatNullable(shipment_1.Weight)], ["Chargeable Weight", formatNullable(shipment_1.ChargeableWeight)], ["Total Pieces", formatNullable(shipment_1.TotalPieces)], ["Service Type", shipment_1.ServiceType], ["Service Level", shipment_1.ServiceLevel], ["Start Date", formatDateTime(shipment_1.StartDate)], ["POL/ETD Date", formatDateTime(shipment_1.PolEtdDate)], ["POD/ETA Date", formatDateTime(shipment_1.PodEtaDate)], ["Due Date", formatDateTime(shipment_1.DueDate)]]);
                    return createElement("div", createObj(ofArray([["className", join(" ", ["container", "my-6", "mx-auto"])], (elems_48 = toList(delay(() => {
                        let elems_25, elems_21, elems_20, elems_24, elems_23;
                        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_25 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_21 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_20 = toList(delay(() => append(singleton_1(createElement("div", {
                            className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                            children: "Details",
                        })), delay(() => collect((matchValue_1) => {
                            let elems_19;
                            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "py-1"])], (elems_19 = [createElement("span", {
                                className: join(" ", ["has-text-weight-semibold"]),
                                children: matchValue_1[0],
                            }), createElement("span", {
                                className: join(" ", []),
                                children: matchValue_1[1],
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))));
                        }, shipmentDetailsFields))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_24 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_23 = toList(delay(() => append(singleton_1(createElement("div", {
                            className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                            children: "Goods",
                        })), delay(() => collect((matchValue_2) => {
                            let elems_22;
                            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "py-1"])], (elems_22 = [createElement("span", {
                                className: join(" ", ["has-text-weight-semibold"]),
                                children: matchValue_2[0],
                            }), createElement("span", {
                                className: join(" ", []),
                                children: matchValue_2[1],
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])]))));
                        }, goodsDetailsFields))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))), delay(() => {
                            let elems_36;
                            return append((((shipment_1.Invoices.length > 0) ? true : (shipment_1.References.length > 0)) ? true : (shipment_1.Containers.length > 0)) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_36 = toList(delay(() => {
                                let elems_28, elems_27, elems_26;
                                return append((shipment_1.Invoices.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_28 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_27 = [createElement("div", {
                                    className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                    children: "Invoices",
                                }), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap"])], (elems_26 = toList(delay(() => map((invoice) => createElement("span", {
                                    className: join(" ", ["mb-1", "mr-4"]),
                                    children: invoice.InvoiceNumber,
                                }), shipment_1.Invoices))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])))) : empty_1(), delay(() => {
                                    let elems_32, elems_31, elems_30;
                                    return append((shipment_1.References.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_32 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_31 = [createElement("div", {
                                        className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                        children: "References",
                                    }), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_30 = toList(delay(() => map((reference) => {
                                        let elems_29;
                                        return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "mb-1"])], (elems_29 = [createElement("span", {
                                            className: join(" ", ["has-text-weight-semibold", "whitespace-nowrap"]),
                                            children: reference.ReferenceCode,
                                        }), createElement("span", {
                                            className: join(" ", []),
                                            children: reference.ReferenceNumber,
                                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])));
                                    }, shipment_1.References))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))) : empty_1(), delay(() => {
                                        let elems_35, elems_34, elems_33;
                                        return (shipment_1.Containers.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_35 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_34 = [createElement("div", {
                                            className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                            children: "Containers",
                                        }), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap"])], (elems_33 = toList(delay(() => map((invoice_1) => createElement("span", {
                                            className: join(" ", ["mb-1", "mr-4"]),
                                            children: invoice_1.ContainerNumber,
                                        }), shipment_1.Containers))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])))) : empty_1();
                                    }));
                                }));
                            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])])))) : empty_1(), delay(() => {
                                let elems_47, elems_46, elems_45, elems_44, elems_43, elems_38, elems_37, elems_42;
                                return (shipment_1.Milestones.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_47 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_46 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_45 = [createElement("div", {
                                    className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                    children: "Milestones",
                                }), createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_44 = [createElement("table", createObj(ofArray([["style", {
                                    minWidth: 450,
                                }], ["className", join(" ", ["table", "is-fullwidth", "is-narrow"])], (elems_43 = [createElement("thead", createObj(singleton((elems_38 = [createElement("tr", createObj(ofArray([["className", join(" ", [])], (elems_37 = toList(delay(() => map((milestoneHeader) => createElement("th", {
                                    className: join(" ", ["pr-2"]),
                                    children: milestoneHeader,
                                }), ["Description", "Estimated Date/Time", "Actual Date/Time", "Reason"]))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])))), createElement("tbody", createObj(ofArray([["className", join(" ", [])], (elems_42 = toList(delay(() => collect((milestone) => {
                                    let elems_39;
                                    return append(singleton_1(createElement("tr", createObj(singleton((elems_39 = [createElement("td", {
                                        children: milestone.Description,
                                        className: join(" ", ["py-2"]),
                                    }), createElement("td", {
                                        children: `${formatDateTime(milestone.EstimatedDate)} ${formatTime(milestone.EstimatedTime)}`,
                                        className: join(" ", ["py-2"]),
                                    }), createElement("td", {
                                        children: `${formatDateTime(milestone.ActualDate)} ${formatTime(milestone.ActualTime)}`,
                                        className: join(" ", ["py-2"]),
                                    }), createElement("td", {
                                        children: milestone.ReasonDescription,
                                        className: join(" ", ["py-2"]),
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))]))))), delay(() => {
                                        let elems_41, elems_40;
                                        return !isNullOrWhiteSpace(milestone.Remark) ? singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", [])], (elems_41 = [createElement("td", createObj(ofArray([["colSpan", 4], ["className", join(" ", ["py-2"])], (elems_40 = [createElement("span", {
                                            className: join(" ", ["has-text-weight-semibold"]),
                                            children: "Remark: ",
                                        }), createElement("span", {
                                            children: milestone.Remark,
                                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_41))])])))) : empty_1();
                                    }));
                                }, shipment_1.Milestones))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_42))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_44))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_45))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_46))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_47))])])))) : empty_1();
                            }));
                        }));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_48))])])));
                })) : (Deferred_resolved(state.CMTSShipment) ? singleton_1(renderShipmentDetails(state.CMTSShipment, (shipment_2) => {
                    let tupledArg_6, tupledArg_7, elems_89;
                    const shipmentDetailsFields_1 = ofArray([["Load #", shipment_2.LoadNumber], ["BOL #", shipment_2.BolNumber], ["Origin", (tupledArg_6 = [shipment_2.Origin, shipment_2.OriginCountry], formatLocation(tupledArg_6[0], tupledArg_6[1]))], ["Destination", (tupledArg_7 = [shipment_2.Destination, shipment_2.DestinationCountry], formatLocation(tupledArg_7[0], tupledArg_7[1]))], ["Carrier", shipment_2.TradingPartnerCarrierName], ["Carrier Pro #", shipment_2.ProNumber]]);
                    const deliveryStop = tryFind((milestone_2) => {
                        if (milestone_2.StopType.toLocaleLowerCase() === "delivery") {
                            return milestone_2.ActualDate != null;
                        }
                        else {
                            return false;
                        }
                    }, sortByDescending((milestone_1) => {
                        if (milestone_1.ActualDate != null) {
                            return value_676(milestone_1.ActualDate);
                        }
                        else {
                            return minValue();
                        }
                    }, shipment_2.Stops, {
                        Compare: compare,
                    }));
                    const pickupStop = tryFind((milestone_4) => {
                        if (milestone_4.StopType.toLocaleLowerCase() === "pickup") {
                            return milestone_4.ActualDate != null;
                        }
                        else {
                            return false;
                        }
                    }, sortBy((milestone_3) => {
                        if (milestone_3.ActualDate != null) {
                            return value_676(milestone_3.ActualDate);
                        }
                        else {
                            return maxValue();
                        }
                    }, shipment_2.Stops, {
                        Compare: compare,
                    }));
                    const goodsDetailsFields_1 = toList(delay(() => append(singleton_1(["Weight", `${formatNullable(shipment_2.Weight)} ${shipment_2.WeightUnit}`]), delay(() => append(singleton_1(["Total Handling Units", formatNullable(shipment_2.TotalHandlingUnitCount)]), delay(() => append(singleton_1(["Total Pieces", formatNullable(shipment_2.TotalPieces)]), delay(() => {
                        let matchValue_3;
                        return append((matchValue_3 = pickupStop, (matchValue_3 != null) ? singleton_1(["Actual Pick Up", formatDateAndTime(matchValue_3.ActualDate)]) : singleton_1(["Planned Pick Up", formatDateAndTime(shipment_2.PlannedStartDate)])), delay(() => {
                            const matchValue_4 = deliveryStop;
                            return (matchValue_4 != null) ? singleton_1(["Actual Delivery", formatDateAndTime(matchValue_4.ActualDate)]) : singleton_1(["Planned Delivery", formatDateAndTime(shipment_2.PlannedEndDate)]);
                        }));
                    }))))))));
                    return createElement("div", createObj(ofArray([["className", join(" ", ["container", "my-6", "mx-auto"])], (elems_89 = toList(delay(() => {
                        let elems_55, elems_51, elems_50, elems_54, elems_53;
                        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_55 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_51 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_50 = toList(delay(() => append(singleton_1(createElement("div", {
                            className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                            children: "Details",
                        })), delay(() => collect((matchValue_5) => {
                            let elems_49;
                            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "py-1"])], (elems_49 = [createElement("span", {
                                className: join(" ", ["has-text-weight-semibold"]),
                                children: matchValue_5[0],
                            }), createElement("span", {
                                className: join(" ", []),
                                children: matchValue_5[1],
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_49))])]))));
                        }, shipmentDetailsFields_1))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_50))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_51))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_54 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_53 = toList(delay(() => append(singleton_1(createElement("div", {
                            className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                            children: "Goods",
                        })), delay(() => collect((matchValue_6) => {
                            let elems_52;
                            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "py-1"])], (elems_52 = [createElement("span", {
                                className: join(" ", ["has-text-weight-semibold"]),
                                children: matchValue_6[0],
                            }), createElement("span", {
                                className: join(" ", []),
                                children: matchValue_6[1],
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_52))])]))));
                        }, goodsDetailsFields_1))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_53))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_54))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_55))])])))), delay(() => {
                            let elems_59, elems_58, elems_57, elems_56;
                            return append((shipment_2.Invoices.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_59 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_58 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_57 = [createElement("div", {
                                className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                children: "Invoices",
                            }), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap"])], (elems_56 = toList(delay(() => map((invoice_2) => createElement("span", {
                                className: join(" ", ["mb-1", "mr-4"]),
                                children: invoice_2.InvoiceNumber,
                            }), shipment_2.Invoices))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_56))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_57))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_58))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_59))])])))) : empty_1(), delay(() => {
                                let elems_68, elems_63, elems_62, elems_61, elems_67, elems_66, elems_65;
                                return append((shipment_2.References.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_68 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_63 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_62 = [createElement("div", {
                                    className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                    children: "Load References",
                                }), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_61 = toList(delay(() => map((reference_1) => {
                                    let elems_60;
                                    return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "mb-1"])], (elems_60 = [createElement("span", {
                                        className: join(" ", ["has-text-weight-semibold"]),
                                        children: reference_1.ReferenceCode,
                                    }), createElement("span", {
                                        className: join(" ", []),
                                        children: reference_1.ReferenceValue,
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_60))])])));
                                }, shipment_2.References.filter((reference_2) => !reference_2.IsOrderReference)))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_61))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_62))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_63))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_67 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_66 = [createElement("div", {
                                    className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                    children: "Order References",
                                }), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_65 = toList(delay(() => map((reference_3) => {
                                    let elems_64;
                                    return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "mb-1"])], (elems_64 = [createElement("span", {
                                        className: join(" ", ["has-text-weight-semibold"]),
                                        children: reference_3.ReferenceCode,
                                    }), createElement("span", {
                                        className: join(" ", []),
                                        children: reference_3.ReferenceValue,
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_64))])])));
                                }, shipment_2.References.filter((reference_4) => reference_4.IsOrderReference)))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_65))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_66))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_67))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_68))])])))) : empty_1(), delay(() => {
                                    let elems_77, elems_76, elems_75, elems_74, elems_73, elems_70, elems_69, elems_72;
                                    return append((shipment_2.Stops.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_77 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_76 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_75 = [createElement("div", {
                                        className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                        children: "Stops",
                                    }), createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], ["style", {
                                        maxHeight: 450 + "px",
                                        overflowY: "auto",
                                    }], (elems_74 = [createElement("table", createObj(ofArray([["style", {
                                        minWidth: 450,
                                    }], ["className", join(" ", ["table", "is-fullwidth", "is-narrow", "table-has-fixed-header"])], (elems_73 = [createElement("thead", createObj(singleton((elems_70 = [createElement("tr", createObj(ofArray([["className", join(" ", [])], (elems_69 = toList(delay(() => map((stopHeader) => createElement("th", {
                                        className: join(" ", ["pr-2"]),
                                        children: stopHeader,
                                    }), ["Stop Number", "Stop Name", "Estimated Date/Time", "Actual Date/Time", "Appointment", "Handling Units", "Gross Weight"]))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_69))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_70))])))), createElement("tbody", createObj(ofArray([["className", join(" ", [])], (elems_72 = toList(delay(() => map((stop) => {
                                        let elems_71, matchValue_7, matchValue_8;
                                        return createElement("tr", createObj(ofArray([["className", join(" ", [])], (elems_71 = [createElement("td", {
                                            className: join(" ", ["py-2"]),
                                            children: formatNullable(stop.StopNumber),
                                        }), createElement("td", {
                                            className: join(" ", ["py-2"]),
                                            children: (matchValue_7 = isNullOrWhiteSpace(stop.StopType), (matchValue_8 = isNullOrWhiteSpace(stop.LocationName), matchValue_7 ? (matchValue_8 ? "" : stop.LocationName) : (matchValue_8 ? "" : toText(printf("%s - %s"))(stop.StopType)(stop.LocationName)))),
                                        }), createElement("td", {
                                            className: join(" ", ["py-2"]),
                                            children: formatDateAndTime(stop.EstimatedDate),
                                        }), createElement("td", {
                                            className: join(" ", ["py-2"]),
                                            children: formatDateAndTime(stop.ActualDate),
                                        }), createElement("td", {
                                            className: join(" ", ["py-2"]),
                                            children: formatDateAndTime(stop.AppointmentDate),
                                        }), createElement("td", {
                                            className: join(" ", ["py-2"]),
                                            children: formatNullable(stop.StopHandlingCount),
                                        }), createElement("td", {
                                            className: join(" ", ["py-2"]),
                                            children: `${formatNullable(stop.Weight)} ${isNullOrWhiteSpace(stop.WeightUnit) ? "" : stop.WeightUnit}`,
                                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_71))])])));
                                    }, shipment_2.Stops))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_72))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_73))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_74))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_75))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_76))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_77))])])))) : empty_1(), delay(() => {
                                        let elems_88, elems_87, elems_86, elems_85, elems_84, elems_79, elems_78, elems_83;
                                        return (shipment_2.Milestones.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_88 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_87 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_86 = [createElement("div", {
                                            className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                            children: "Milestones",
                                        }), createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], ["style", {
                                            maxHeight: 450 + "px",
                                            overflowY: "auto",
                                        }], (elems_85 = [createElement("table", createObj(ofArray([["style", {
                                            minWidth: 450,
                                        }], ["className", join(" ", ["table", "is-fullwidth", "is-narrow", "table-has-fixed-header"])], (elems_84 = [createElement("thead", createObj(singleton((elems_79 = [createElement("tr", createObj(ofArray([["className", join(" ", [])], (elems_78 = toList(delay(() => map((milestoneHeader_1) => createElement("th", {
                                            className: join(" ", ["pr-2"]),
                                            children: milestoneHeader_1,
                                        }), ["Stop Number", "Description", "Status Date/Time", "Reason Type"]))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_78))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_79))])))), createElement("tbody", createObj(ofArray([["className", join(" ", [])], (elems_83 = toList(delay(() => collect((milestone_5) => {
                                            let elems_80;
                                            return append(singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", [])], (elems_80 = [createElement("td", {
                                                className: join(" ", ["py-2"]),
                                                children: formatNullable(milestone_5.StopNumber),
                                            }), createElement("td", {
                                                className: join(" ", ["py-2"]),
                                                children: milestone_5.Description,
                                            }), createElement("td", {
                                                className: join(" ", ["py-2"]),
                                                children: formatDateAndTime(milestone_5.EventDate),
                                            }), createElement("td", {
                                                className: join(" ", ["py-2"]),
                                                children: milestone_5.ReasonType,
                                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_80))])])))), delay(() => {
                                                let elems_82, elems_81;
                                                return !isNullOrWhiteSpace(milestone_5.Remark) ? singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", [])], (elems_82 = [createElement("td", createObj(ofArray([["colSpan", 4], ["className", join(" ", ["py-2"])], (elems_81 = [createElement("span", {
                                                    className: join(" ", ["has-text-weight-semibold"]),
                                                    children: "Remark: ",
                                                }), createElement("span", {
                                                    children: milestone_5.Remark,
                                                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_81))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_82))])])))) : empty_1();
                                            }));
                                        }, shipment_2.Milestones))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_83))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_84))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_85))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_86))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_87))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_88))])])))) : empty_1();
                                    }));
                                }));
                            }));
                        }));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_89))])])));
                })) : (Deferred_resolved(state.CFSCSLShipment) ? singleton_1(renderShipmentDetails(state.CFSCSLShipment, (shipment_3) => {
                    let tupledArg_8, tupledArg_9, elems_114;
                    const shipmentDetailsFields_2 = ofArray([["Pro #", shipment_3.ShipmentId], ["Origin", (tupledArg_8 = [shipment_3.OriginCity, shipment_3.OriginState], formatLocation(tupledArg_8[0], tupledArg_8[1]))], ["Destination", (tupledArg_9 = [shipment_3.DestinationCity, shipment_3.DestinationState], formatLocation(tupledArg_9[0], tupledArg_9[1]))], ["Estimated Pickup", formatDateAndTime(shipment_3.EstimatedPickupDate)], ["Estimated Delivery", formatDateAndTime(shipment_3.EstimatedDeliveryDate)]]);
                    const goodsDetailsFields_2 = ofArray([["Weight", `${formatNullable(shipment_3.Weight)} lbs`], ["Total Pieces", formatNullable(shipment_3.TotalPieces)], ["Equipment Type", shipment_3.EquipmentType], ["Description", shipment_3.Description]]);
                    return createElement("div", createObj(ofArray([["className", join(" ", ["container", "my-6", "mx-auto"])], (elems_114 = toList(delay(() => {
                        let elems_96, elems_92, elems_91, elems_95, elems_94;
                        return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_96 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_92 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_91 = toList(delay(() => append(singleton_1(createElement("div", {
                            className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                            children: "Details",
                        })), delay(() => collect((matchValue_11) => {
                            let elems_90;
                            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "py-1"])], (elems_90 = [createElement("span", {
                                className: join(" ", ["has-text-weight-semibold"]),
                                children: matchValue_11[0],
                            }), createElement("span", {
                                className: join(" ", []),
                                children: matchValue_11[1],
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_90))])]))));
                        }, shipmentDetailsFields_2))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_91))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_92))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_95 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_94 = toList(delay(() => append(singleton_1(createElement("div", {
                            className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                            children: "Goods",
                        })), delay(() => collect((matchValue_12) => {
                            let elems_93;
                            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "py-1"])], (elems_93 = [createElement("span", {
                                className: join(" ", ["has-text-weight-semibold"]),
                                children: matchValue_12[0],
                            }), createElement("span", {
                                className: join(" ", []),
                                children: matchValue_12[1],
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_93))])]))));
                        }, goodsDetailsFields_2))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_94))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_95))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_96))])])))), delay(() => {
                            let elems_104;
                            return append(((shipment_3.Invoices.length > 0) ? true : (shipment_3.References.length > 0)) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_104 = toList(delay(() => {
                                let elems_99, elems_98, elems_97;
                                return append((shipment_3.Invoices.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_99 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_98 = [createElement("div", {
                                    className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                    children: "Invoices",
                                }), createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-wrap-wrap"])], (elems_97 = toList(delay(() => map((invoice_3) => createElement("span", {
                                    className: join(" ", ["mb-1", "mr-4"]),
                                    children: invoice_3.InvoiceNumber,
                                }), shipment_3.Invoices))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_97))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_98))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_99))])])))) : empty_1(), delay(() => {
                                    let elems_103, elems_102, elems_101;
                                    return (shipment_3.References.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_103 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_102 = [createElement("div", {
                                        className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                        children: "References",
                                    }), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_101 = toList(delay(() => map((reference_5) => {
                                        let elems_100;
                                        return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between", "mb-1"])], (elems_100 = [createElement("span", {
                                            className: join(" ", ["has-text-weight-semibold"]),
                                            children: reference_5.ReferenceName,
                                        }), createElement("span", {
                                            className: join(" ", []),
                                            children: reference_5.ReferenceValue,
                                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_100))])])));
                                    }, shipment_3.References))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_101))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_102))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_103))])])))) : empty_1();
                                }));
                            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_104))])])))) : empty_1(), delay(() => {
                                let elems_113, elems_112, elems_111, elems_110, elems_109, elems_106, elems_105, elems_108;
                                return (shipment_3.Milestones.length > 0) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_113 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_112 = [createElement("div", createObj(ofArray([["className", join(" ", ["box", "is-fullheight"])], (elems_111 = [createElement("div", {
                                    className: join(" ", ["has-text-weight-semibold", "mb-2", "has-text-primary", "is-uppercase"]),
                                    children: "Milestones",
                                }), createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_110 = [createElement("table", createObj(ofArray([["style", {
                                    minWidth: 450,
                                }], ["className", join(" ", ["table", "is-fullwidth", "is-narrow"])], (elems_109 = [createElement("thead", createObj(ofArray([["className", join(" ", [])], (elems_106 = [createElement("tr", createObj(ofArray([["className", join(" ", [])], (elems_105 = toList(delay(() => map((milestoneHeader_2) => createElement("th", {
                                    className: join(" ", ["pr-2"]),
                                    children: milestoneHeader_2,
                                }), ["Milestone", "Location", "Estimated Date/Time", "Actual Date/Time"]))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_105))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_106))])]))), createElement("tbody", createObj(ofArray([["className", join(" ", [])], (elems_108 = toList(delay(() => map((milestone_6) => {
                                    let elems_107, tupledArg_10;
                                    return createElement("tr", createObj(ofArray([["className", join(" ", [])], (elems_107 = [createElement("td", {
                                        className: join(" ", ["py-2"]),
                                        children: milestone_6.PointName,
                                    }), createElement("td", {
                                        className: join(" ", ["py-2"]),
                                        children: (tupledArg_10 = [milestone_6.City, milestone_6.State], formatLocation(tupledArg_10[0], tupledArg_10[1])),
                                    }), createElement("td", {
                                        className: join(" ", ["py-2"]),
                                        children: formatDateAndTime(milestone_6.EstimatedDate),
                                    }), createElement("td", {
                                        className: join(" ", ["py-2"]),
                                        children: formatDateAndTime(milestone_6.ActualDate),
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_107))])])));
                                }, shipment_3.Milestones))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_108))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_109))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_110))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_111))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_112))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_113))])])))) : empty_1();
                            }));
                        }));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_114))])])));
                })) : singleton_1(defaultOf())));
            }
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_115))])])));
    const disclaimer = createElement("div", createObj(ofArray([["className", join(" ", ["box", "disclaimer", "is-fixed-bottom", "mx-5", "is-size-7"])], ["style", {
        marginBottom: 70,
    }], (elems_117 = [createElement("div", createObj(ofArray([["className", join(" ", ["has-text-centered"])], (elems_116 = [createElement("span", {
        children: ["Only general shipment information is shown. "],
    }), createElement("span", {
        children: ["Request access to "],
    }), createElement("a", {
        className: join(" ", ["is-underlined"]),
        target: "_blank",
        href: "https://craneww.com/logistics-technology/shipment-tracking",
        children: "C-View",
    }), createElement("span", {
        children: [" to see more details."],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_116))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_117))])])));
    const footer = createElement("footer", createObj(ofArray([["className", join(" ", ["footer", "is-fixed-bottom"])], (elems_122 = [createElement("div", createObj(ofArray([["className", join(" ", ["content", "has-text-centered", "is-size-7"])], (elems_121 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_120 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_118 = [createElement("a", {
        className: join(" ", ["mx-4", "has-text-light"]),
        href: "https://craneww.com/privacy-policy/",
        target: "_blank",
        children: "Privacy Policy",
    }), createElement("a", {
        className: join(" ", ["mx-4", "has-text-light"]),
        href: "https://craneww.com/terms-of-service/",
        target: "_blank",
        children: "Terms of Service",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_118))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-hidden-mobile"])], (elems_119 = [createElement("a", {
        className: join(" ", ["has-text-light"]),
        href: "https://craneww.com/",
        target: "_blank",
        children: "Copyright © 2023 Crane Worldwide Logistics. All rights reserved.",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_119))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_120))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_121))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_122))])])));
    return RouterModule_router(createObj(ofArray([["onUrlChanged", (arg_2) => {
        iterate((value_673) => {
            dispatch(new Msg(2, [value_673, true]));
        }, toArray(tryParseTrackingNumber(arg_2)));
    }], (elements = singleton(createElement("div", {
        className: join(" ", ["is-flex", "is-flex-direction-column"]),
        children: Interop_reactApi.Children.toArray([navbar, main, disclaimer, footer]),
    })), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

