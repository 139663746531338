import { Record } from "../WebTracker.UI/fable_modules/fable-library-js.4.20.0/Types.js";
import { bool_type, decimal_type, class_type, option_type, string_type, record_type, array_type, int32_type } from "../WebTracker.UI/fable_modules/fable-library-js.4.20.0/Reflection.js";

export class PagedData$1 extends Record {
    constructor(Page, PageSize, TotalCount, Data) {
        super();
        this.Page = (Page | 0);
        this.PageSize = (PageSize | 0);
        this.TotalCount = (TotalCount | 0);
        this.Data = Data;
    }
}

export function PagedData$1_$reflection(gen0) {
    return record_type("WebTracker.Shared.PagedData`1", [gen0], PagedData$1, () => [["Page", int32_type], ["PageSize", int32_type], ["TotalCount", int32_type], ["Data", array_type(gen0)]]);
}

export class ShipmentLite extends Record {
    constructor(ShipmentId, CustomerType, ServiceLevel, ServiceType, Origin, OriginCountryCode, Destination, DestinationCountryCode, HumanReadableId, HumanReadableIdLabel) {
        super();
        this.ShipmentId = ShipmentId;
        this.CustomerType = CustomerType;
        this.ServiceLevel = ServiceLevel;
        this.ServiceType = ServiceType;
        this.Origin = Origin;
        this.OriginCountryCode = OriginCountryCode;
        this.Destination = Destination;
        this.DestinationCountryCode = DestinationCountryCode;
        this.HumanReadableId = HumanReadableId;
        this.HumanReadableIdLabel = HumanReadableIdLabel;
    }
}

export function ShipmentLite_$reflection() {
    return record_type("WebTracker.Shared.ShipmentLite", [], ShipmentLite, () => [["ShipmentId", string_type], ["CustomerType", string_type], ["ServiceLevel", string_type], ["ServiceType", string_type], ["Origin", string_type], ["OriginCountryCode", string_type], ["Destination", string_type], ["DestinationCountryCode", string_type], ["HumanReadableId", string_type], ["HumanReadableIdLabel", string_type]]);
}

export class TMF_Reference extends Record {
    constructor(Gshpid, ReferenceCode, ReferenceNumber) {
        super();
        this.Gshpid = Gshpid;
        this.ReferenceCode = ReferenceCode;
        this.ReferenceNumber = ReferenceNumber;
    }
}

export function TMF_Reference_$reflection() {
    return record_type("WebTracker.Shared.TMF.Reference", [], TMF_Reference, () => [["Gshpid", string_type], ["ReferenceCode", string_type], ["ReferenceNumber", string_type]]);
}

export class TMF_Invoice extends Record {
    constructor(Gshpid, InvoiceNumber, ApplyToDocNo) {
        super();
        this.Gshpid = Gshpid;
        this.InvoiceNumber = InvoiceNumber;
        this.ApplyToDocNo = ApplyToDocNo;
    }
}

export function TMF_Invoice_$reflection() {
    return record_type("WebTracker.Shared.TMF.Invoice", [], TMF_Invoice, () => [["Gshpid", string_type], ["InvoiceNumber", string_type], ["ApplyToDocNo", option_type(string_type)]]);
}

export class TMF_Milestone extends Record {
    constructor(Gshpid, Sno, Code, Description, EstimatedDate, EstimatedTime, ActualDate, ActualTime, ReasonCode, ReasonDescription, Remark) {
        super();
        this.Gshpid = Gshpid;
        this.Sno = (Sno | 0);
        this.Code = Code;
        this.Description = Description;
        this.EstimatedDate = EstimatedDate;
        this.EstimatedTime = EstimatedTime;
        this.ActualDate = ActualDate;
        this.ActualTime = ActualTime;
        this.ReasonCode = ReasonCode;
        this.ReasonDescription = ReasonDescription;
        this.Remark = Remark;
    }
}

export function TMF_Milestone_$reflection() {
    return record_type("WebTracker.Shared.TMF.Milestone", [], TMF_Milestone, () => [["Gshpid", string_type], ["Sno", int32_type], ["Code", string_type], ["Description", string_type], ["EstimatedDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["EstimatedTime", string_type], ["ActualDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["ActualTime", string_type], ["ReasonCode", string_type], ["ReasonDescription", string_type], ["Remark", string_type]]);
}

export class TMF_Container extends Record {
    constructor(Gshpid, ContainerNumber) {
        super();
        this.Gshpid = Gshpid;
        this.ContainerNumber = ContainerNumber;
    }
}

export function TMF_Container_$reflection() {
    return record_type("WebTracker.Shared.TMF.Container", [], TMF_Container, () => [["Gshpid", string_type], ["ContainerNumber", string_type]]);
}

export class TMF_Shipment extends Record {
    constructor(Gshpid, AwbNumber, MasterAwbNumber, BookingNumber, JobNumber, ServiceLevel, ServiceType, StartDate, DueDate, Origin, OriginCountry, PolName, PolEtdDate, PodName, PodEtaDate, Destination, DestinationCountry, ChargeableWeight, Weight, TotalPieces, References, Invoices, Milestones, Containers) {
        super();
        this.Gshpid = Gshpid;
        this.AwbNumber = AwbNumber;
        this.MasterAwbNumber = MasterAwbNumber;
        this.BookingNumber = BookingNumber;
        this.JobNumber = JobNumber;
        this.ServiceLevel = ServiceLevel;
        this.ServiceType = ServiceType;
        this.StartDate = StartDate;
        this.DueDate = DueDate;
        this.Origin = Origin;
        this.OriginCountry = OriginCountry;
        this.PolName = PolName;
        this.PolEtdDate = PolEtdDate;
        this.PodName = PodName;
        this.PodEtaDate = PodEtaDate;
        this.Destination = Destination;
        this.DestinationCountry = DestinationCountry;
        this.ChargeableWeight = ChargeableWeight;
        this.Weight = Weight;
        this.TotalPieces = TotalPieces;
        this.References = References;
        this.Invoices = Invoices;
        this.Milestones = Milestones;
        this.Containers = Containers;
    }
}

export function TMF_Shipment_$reflection() {
    return record_type("WebTracker.Shared.TMF.Shipment", [], TMF_Shipment, () => [["Gshpid", string_type], ["AwbNumber", string_type], ["MasterAwbNumber", string_type], ["BookingNumber", string_type], ["JobNumber", string_type], ["ServiceLevel", string_type], ["ServiceType", string_type], ["StartDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["DueDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["Origin", string_type], ["OriginCountry", string_type], ["PolName", string_type], ["PolEtdDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["PodName", string_type], ["PodEtaDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["Destination", string_type], ["DestinationCountry", string_type], ["ChargeableWeight", class_type("System.Nullable`1", [decimal_type])], ["Weight", class_type("System.Nullable`1", [decimal_type])], ["TotalPieces", class_type("System.Nullable`1", [decimal_type])], ["References", array_type(TMF_Reference_$reflection())], ["Invoices", array_type(TMF_Invoice_$reflection())], ["Milestones", array_type(TMF_Milestone_$reflection())], ["Containers", array_type(TMF_Container_$reflection())]]);
}

export class GTMS_Stop extends Record {
    constructor(ShipmentId, StopNumber, StopType, LocationName, EstimatedDate, ActualDate, AppointmentDate, StopHandlingCount, Weight, WeightUnit) {
        super();
        this.ShipmentId = ShipmentId;
        this.StopNumber = StopNumber;
        this.StopType = StopType;
        this.LocationName = LocationName;
        this.EstimatedDate = EstimatedDate;
        this.ActualDate = ActualDate;
        this.AppointmentDate = AppointmentDate;
        this.StopHandlingCount = StopHandlingCount;
        this.Weight = Weight;
        this.WeightUnit = WeightUnit;
    }
}

export function GTMS_Stop_$reflection() {
    return record_type("WebTracker.Shared.GTMS.Stop", [], GTMS_Stop, () => [["ShipmentId", string_type], ["StopNumber", class_type("System.Nullable`1", [int32_type])], ["StopType", string_type], ["LocationName", string_type], ["EstimatedDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["ActualDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["AppointmentDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["StopHandlingCount", class_type("System.Nullable`1", [int32_type])], ["Weight", class_type("System.Nullable`1", [decimal_type])], ["WeightUnit", string_type]]);
}

export class GTMS_Milestone extends Record {
    constructor(ShipmentId, StopNumber, Description, EventDate, ReasonType, Remark) {
        super();
        this.ShipmentId = ShipmentId;
        this.StopNumber = StopNumber;
        this.Description = Description;
        this.EventDate = EventDate;
        this.ReasonType = ReasonType;
        this.Remark = Remark;
    }
}

export function GTMS_Milestone_$reflection() {
    return record_type("WebTracker.Shared.GTMS.Milestone", [], GTMS_Milestone, () => [["ShipmentId", string_type], ["StopNumber", class_type("System.Nullable`1", [int32_type])], ["Description", string_type], ["EventDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["ReasonType", string_type], ["Remark", string_type]]);
}

export class GTMS_Invoice extends Record {
    constructor(ShipmentId, InvoiceNumber, InvoiceDate, InvoiceStatus) {
        super();
        this.ShipmentId = ShipmentId;
        this.InvoiceNumber = InvoiceNumber;
        this.InvoiceDate = InvoiceDate;
        this.InvoiceStatus = InvoiceStatus;
    }
}

export function GTMS_Invoice_$reflection() {
    return record_type("WebTracker.Shared.GTMS.Invoice", [], GTMS_Invoice, () => [["ShipmentId", string_type], ["InvoiceNumber", string_type], ["InvoiceDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["InvoiceStatus", string_type]]);
}

export class GTMS_Reference extends Record {
    constructor(ShipmentId, ReferenceCode, ReferenceValue, IsOrderReference) {
        super();
        this.ShipmentId = ShipmentId;
        this.ReferenceCode = ReferenceCode;
        this.ReferenceValue = ReferenceValue;
        this.IsOrderReference = IsOrderReference;
    }
}

export function GTMS_Reference_$reflection() {
    return record_type("WebTracker.Shared.GTMS.Reference", [], GTMS_Reference, () => [["ShipmentId", string_type], ["ReferenceCode", string_type], ["ReferenceValue", string_type], ["IsOrderReference", bool_type]]);
}

export class GTMS_Shipment extends Record {
    constructor(ShipmentId, LoadNumber, ProNumber, BolNumber, ServiceOptionName, OrderCount, CreatedBy, PlannedStartDate, PlannedEndDate, Origin, OriginCountry, Destination, DestinationCountry, TradingPartnerCarrierName, Weight, WeightUnit, TotalPieces, TotalHandlingUnitCount, References, Invoices, Stops, Milestones) {
        super();
        this.ShipmentId = ShipmentId;
        this.LoadNumber = LoadNumber;
        this.ProNumber = ProNumber;
        this.BolNumber = BolNumber;
        this.ServiceOptionName = ServiceOptionName;
        this.OrderCount = (OrderCount | 0);
        this.CreatedBy = CreatedBy;
        this.PlannedStartDate = PlannedStartDate;
        this.PlannedEndDate = PlannedEndDate;
        this.Origin = Origin;
        this.OriginCountry = OriginCountry;
        this.Destination = Destination;
        this.DestinationCountry = DestinationCountry;
        this.TradingPartnerCarrierName = TradingPartnerCarrierName;
        this.Weight = Weight;
        this.WeightUnit = WeightUnit;
        this.TotalPieces = TotalPieces;
        this.TotalHandlingUnitCount = TotalHandlingUnitCount;
        this.References = References;
        this.Invoices = Invoices;
        this.Stops = Stops;
        this.Milestones = Milestones;
    }
}

export function GTMS_Shipment_$reflection() {
    return record_type("WebTracker.Shared.GTMS.Shipment", [], GTMS_Shipment, () => [["ShipmentId", string_type], ["LoadNumber", string_type], ["ProNumber", string_type], ["BolNumber", string_type], ["ServiceOptionName", string_type], ["OrderCount", int32_type], ["CreatedBy", string_type], ["PlannedStartDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["PlannedEndDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["Origin", string_type], ["OriginCountry", string_type], ["Destination", string_type], ["DestinationCountry", string_type], ["TradingPartnerCarrierName", string_type], ["Weight", class_type("System.Nullable`1", [decimal_type])], ["WeightUnit", string_type], ["TotalPieces", class_type("System.Nullable`1", [decimal_type])], ["TotalHandlingUnitCount", class_type("System.Nullable`1", [int32_type])], ["References", array_type(GTMS_Reference_$reflection())], ["Invoices", array_type(GTMS_Invoice_$reflection())], ["Stops", array_type(GTMS_Stop_$reflection())], ["Milestones", array_type(GTMS_Milestone_$reflection())]]);
}

export class Aljex_Milestone extends Record {
    constructor(ShipmentId, City, State, PointType, PointName, EstimatedDate, ActualDate) {
        super();
        this.ShipmentId = ShipmentId;
        this.City = City;
        this.State = State;
        this.PointType = PointType;
        this.PointName = PointName;
        this.EstimatedDate = EstimatedDate;
        this.ActualDate = ActualDate;
    }
}

export function Aljex_Milestone_$reflection() {
    return record_type("WebTracker.Shared.Aljex.Milestone", [], Aljex_Milestone, () => [["ShipmentId", string_type], ["City", string_type], ["State", string_type], ["PointType", string_type], ["PointName", string_type], ["EstimatedDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["ActualDate", class_type("System.Nullable`1", [class_type("System.DateTime")])]]);
}

export class Aljex_Invoice extends Record {
    constructor(ShipmentId, InvoiceNumber) {
        super();
        this.ShipmentId = ShipmentId;
        this.InvoiceNumber = InvoiceNumber;
    }
}

export function Aljex_Invoice_$reflection() {
    return record_type("WebTracker.Shared.Aljex.Invoice", [], Aljex_Invoice, () => [["ShipmentId", string_type], ["InvoiceNumber", string_type]]);
}

export class Aljex_Reference extends Record {
    constructor(ShipmentId, ReferenceName, ReferenceValue) {
        super();
        this.ShipmentId = ShipmentId;
        this.ReferenceName = ReferenceName;
        this.ReferenceValue = ReferenceValue;
    }
}

export function Aljex_Reference_$reflection() {
    return record_type("WebTracker.Shared.Aljex.Reference", [], Aljex_Reference, () => [["ShipmentId", string_type], ["ReferenceName", string_type], ["ReferenceValue", string_type]]);
}

export class Aljex_Shipment extends Record {
    constructor(ShipmentId, ReferenceNumber, EstimatedPickupDate, EstimatedDeliveryDate, OriginCity, OriginState, DestinationCity, EquipmentType, Description, DestinationState, Weight, TotalPieces, References, Invoices, Milestones) {
        super();
        this.ShipmentId = ShipmentId;
        this.ReferenceNumber = ReferenceNumber;
        this.EstimatedPickupDate = EstimatedPickupDate;
        this.EstimatedDeliveryDate = EstimatedDeliveryDate;
        this.OriginCity = OriginCity;
        this.OriginState = OriginState;
        this.DestinationCity = DestinationCity;
        this.EquipmentType = EquipmentType;
        this.Description = Description;
        this.DestinationState = DestinationState;
        this.Weight = Weight;
        this.TotalPieces = TotalPieces;
        this.References = References;
        this.Invoices = Invoices;
        this.Milestones = Milestones;
    }
}

export function Aljex_Shipment_$reflection() {
    return record_type("WebTracker.Shared.Aljex.Shipment", [], Aljex_Shipment, () => [["ShipmentId", string_type], ["ReferenceNumber", string_type], ["EstimatedPickupDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["EstimatedDeliveryDate", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["OriginCity", string_type], ["OriginState", string_type], ["DestinationCity", string_type], ["EquipmentType", string_type], ["Description", string_type], ["DestinationState", string_type], ["Weight", class_type("System.Nullable`1", [decimal_type])], ["TotalPieces", class_type("System.Nullable`1", [decimal_type])], ["References", array_type(Aljex_Reference_$reflection())], ["Invoices", array_type(Aljex_Invoice_$reflection())], ["Milestones", array_type(Aljex_Milestone_$reflection())]]);
}

