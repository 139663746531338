import { parse } from "../fable-library-js.4.20.0/Int32.js";
import * as react from "react";
import { ProgramModule_view, ProgramModule_withSetState } from "../Fable.Elmish.4.1.0/program.fs.js";
import { uncurry2 } from "../fable-library-js.4.20.0/Util.js";
import * as client from "react-dom/client";
import * as react_dom from "react-dom";
import { Common_lazyView2With } from "./common.fs.js";

export const Program_Internal_useRootApi = (() => {
    try {
        return parse(react.version.slice(undefined, 1 + 1), 511, false, 32) >= 18;
    }
    catch (matchValue) {
        return false;
    }
})();

export function Program_Internal_withReactBatchedUsing(lazyView2With, placeholderId, program) {
    let lastRequest, root;
    return ProgramModule_withSetState(uncurry2((lastRequest = undefined, Program_Internal_useRootApi ? ((root = client.createRoot(document.getElementById(placeholderId)), (model) => ((dispatch) => {
        if (lastRequest != null) {
            const r = lastRequest;
            window.cancelAnimationFrame(r);
        }
        lastRequest = window.requestAnimationFrame((_arg) => {
            root.render(lazyView2With((x) => ((y) => (x === y)), ProgramModule_view(program), model, dispatch));
        });
    }))) : ((model_1) => ((dispatch_1) => {
        if (lastRequest != null) {
            const r_1 = lastRequest;
            window.cancelAnimationFrame(r_1);
        }
        lastRequest = window.requestAnimationFrame((_arg_1) => {
            react_dom.render(lazyView2With((x_1) => ((y_1) => (x_1 === y_1)), ProgramModule_view(program), model_1, dispatch_1), document.getElementById(placeholderId));
        });
    })))), program);
}

export function Program_Internal_withReactSynchronousUsing(lazyView2With, placeholderId, program) {
    let root;
    return ProgramModule_withSetState(uncurry2(Program_Internal_useRootApi ? ((root = client.createRoot(document.getElementById(placeholderId)), (model) => ((dispatch) => {
        root.render(lazyView2With((x) => ((y) => (x === y)), ProgramModule_view(program), model, dispatch));
    }))) : ((model_1) => ((dispatch_1) => {
        react_dom.render(lazyView2With((x_1) => ((y_1) => (x_1 === y_1)), ProgramModule_view(program), model_1, dispatch_1), document.getElementById(placeholderId));
    }))), program);
}

export function Program_Internal_withReactHydrateUsing(lazyView2With, placeholderId, program) {
    let root;
    return ProgramModule_withSetState(uncurry2(Program_Internal_useRootApi ? ((root = undefined, (model) => ((dispatch) => {
        if (root != null) {
            const root_1 = root;
            root_1.render(lazyView2With((x_1) => ((y_1) => (x_1 === y_1)), ProgramModule_view(program), model, dispatch));
        }
        else {
            root = client.hydrateRoot(document.getElementById(placeholderId), lazyView2With((x) => ((y) => (x === y)), ProgramModule_view(program), model, dispatch));
        }
    }))) : ((model_1) => ((dispatch_1) => {
        react_dom.hydrate(lazyView2With((x_2) => ((y_2) => (x_2 === y_2)), ProgramModule_view(program), model_1, dispatch_1), document.getElementById(placeholderId));
    }))), program);
}

/**
 * Renders React root component inside html element identified by placeholderId.
 * Uses `requestAnimationFrame` to batch updates to prevent drops in frame rate.
 * NOTE: This may have unexpected effects in React controlled inputs, see https://github.com/elmish/react/issues/12
 */
export function Program_withReactBatched(placeholderId, program) {
    return Program_Internal_withReactBatchedUsing((equal, view, state, dispatch) => Common_lazyView2With(uncurry2(equal), uncurry2(view), state, dispatch), placeholderId, program);
}

/**
 * Renders React root component inside html element identified by placeholderId.
 * New renders are triggered immediately after an update.
 */
export function Program_withReactSynchronous(placeholderId, program) {
    return Program_Internal_withReactSynchronousUsing((equal, view, state, dispatch) => Common_lazyView2With(uncurry2(equal), uncurry2(view), state, dispatch), placeholderId, program);
}

/**
 * Renders React root component inside html element identified by placeholderId using `React.hydrate`.
 */
export function Program_withReactHydrate(placeholderId, program) {
    return Program_Internal_withReactHydrateUsing((equal, view, state, dispatch) => Common_lazyView2With(uncurry2(equal), uncurry2(view), state, dispatch), placeholderId, program);
}

